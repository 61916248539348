//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.goods-item-block {
  width: 234px;
  height: 358px;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 25px 0 25px;
  background-color: $color-white;
  &.noBorder {
    border: none;
  }
  &:hover {
    border: 1px solid $color-primary;
  }
  &.bigDiv {
    width: 256px;
    height: 375px;
    .goods-img {
      width: 100%;
      height: 202px;
    }
  }
  .goods-img {
    position: relative;
    width: 100%;
    height: 185px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .img {
      max-width: 100%;
      max-height: 100%;
    }
    .small-img {
      width: 140px;
      height: 134.7px;
    }
    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 99;
      overflow: hidden;
      background: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      .tips {
        width: 70px;
        height: 70px;
        font-size: 14px;
        color: $color-white;
        background-color: $color-black;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
  .goods-price-cart {
    width: 100%;
    height: 18px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 7px;
    .no-login-price {
      font-size: 18px;
      color: #fe3b30;
      line-height: 1;
    }
    .goods-price {
      display: flex;
      align-items: flex-end;
      .price-tags {
        width: 44px;
        height: 17px;
        background: #fe3b30;
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 17px;
        margin-right: 4px;
      }
      .price-num {
        color: #fe3b30;
        font-size: 18px;
        line-height: 1;
        &.over-text {
          color: $color-999;
        }
      }
    }
    .goods-cart {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 15.69px;
        height: 15.61px;
      }
      goods-cart-button::before {
        content: "";
        position: absolute;
        top: -20px;
        right: -20px;
        bottom: -20px;
        left: -20px;
        background-color: red;
      }
    }
    // .goods-cart::before {
      
    // }
  }
  .goods-title {
    width: 100%;
    height: 36px;
    font-size: 12px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    margin-bottom: 7px;
    cursor: pointer;
    &.over-text {
      color: $color-999;
    }
  }
  .goods-tags {
    width: 100%;
    height: 16px;
    margin-bottom: 7px;
    display: flex;
    font-size: 12px;
    .discount-tags {
      padding: 0 3px;
      height: 16px;
      line-height: 13px;
      text-align: center;
      color: #fe3b30;
      border: solid 1px #fe3b30;
      margin-right: 4px;
    }
    .collect-tags {
      width: 42px;
      height: 16px;
      line-height: 13px;
      text-align: center;
      color: $color-primary;
      border: solid 1px $color-primary;
    }
  }
  .specs-item {
    width: 100%;
    height: 16px;
    margin-bottom: 7px;
    color: $color-999;
    font-size: 12px;
    line-height: 16px;
  }
  .tax-rate-item {
    width: 100%;
    color: $color-999;
    font-size: 12px;
    line-height: 16px;
  }
}