//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.recommendContainer {
    .goodsModule {
        margin-bottom: 20px;
        .moduleTitle {
            padding: 14px 0;
            font-size: 22px;
            color: $color-333;
        }
        .moduleContainer {
            display: flex;
            .moduleBanner {
                width: 234px;
                height: 358px;
                margin-right: 14px;
                background-color: $color-white;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .moduleGoodsView {
                width: 978px;
                overflow: hidden;
                position: relative;
        
                .moduleOption {
                    position: absolute;
                    height: 36px;
                    width: 36px;
                    top: 50%;
                    margin-top: -18px;
                    z-index: 999;
        
                    &.left {
                        left: 0;
                    }
        
                    &.right {
                        right: 0;
                    }
        
                    &:hover {
                        cursor: pointer;
                    }
                }
        
                .moduleOptionIcon {
                    position: absolute;
                    width: 26px;
                    top: 50%;
                    margin-top: -20px;
                    z-index: 999;
        
                    &.left {
                        left: -50px;
                    }
        
                    &.right {
                        right: -50px;
                    }
        
                    &:hover {
                        cursor: pointer;
                    }
                }
        
                .moduleGoodsList {
                    display: flex;
                    .moduleGoodsBlock {
                        display: flex !important;
        
                        &>div {
                            margin-right: 14px;
        
                            // &:nth-of-type(4n) {
                            //     margin-right: 0;
                            // }
                            &.lasView {
                                margin-right: 0;
                            }
                        }
                    }
        
                    .similarGoodsBlock {
                        &>div {
                            margin-right: 14px;
        
                            // &:nth-of-type(4n) {
                            //     margin-right: 0;
                            // }
                            &.lasView {
                                margin-right: 0;
                            }
                        }
        
                        .GoodContainer {
                            border: 1px solid #dddddd;
                            width: 256px;
                            height: 379px;
        
                            .goodMainImg {
                                width: 206px;
                                height: 206px;
                            }
                        }
                    }
                }
            }
        }
    }
}