//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.GoodSearchContainer {
    height: 28px;
    width: 342px;

    .ant-select-auto-complete {
        height: 100%;
        width: 100%;

        .ant-input {
            padding: 3px 10px 3px 10px;
            height: 28px;
            font-size: 14px;
            color: $color-333;
        }

        .ant-btn-primary {
            background-color: $color-primary;
            border-color: $color-primary;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            padding: 0;
            width: 50px;
        }
    }
}

.GoodNoSearchContainer {
    height: 28px;
    width: 214px;
    .ant-select-auto-complete {
        height: 100%;
        width: 100%;

        .ant-input {
            padding: 3px 10px 3px 10px;
            height: 28px;
            font-size: 14px;
            color: $color-333;
        }

        .ant-btn-primary {
            background-color: $color-primary;
            border-color: $color-primary;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            padding: 0;
            width: 50px;
        }
    }
}

.GoodSearchSelectDown {
    padding: 0;
    background-color: $color-white;

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: $color-white;
    }

    .searchItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 9px;
        font-size: 12px;
        color: $color-333;
        flex: 1;

        .itemDescView {
            display: flex;
            justify-content: center;
            flex: 1;

            .descTitle {
                margin-right: 5px;
                flex: 1;
                width: 120px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .itemPriceView {
            width: 70px;
            text-align: right;
        }

        &:hover {
            cursor: pointer;
            background-color: #eeeeee;
            color: $color-primary;
        }
    }
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    width: 80px;
    box-shadow: 0 0 0 #ffffff;
}
.ant-input-affix-wrapper {
    height: 28px;
    padding: 0 11px 0 0;
}

.GoodSearchContainer .ant-select-auto-complete .ant-input {
    height: auto;
}