body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    min-height: 100%;
    position: relative;
}
.hide-small-bottom {
    padding-bottom: 60px !important;
}
#root>div {
    padding-bottom: 340px;
    min-width: 1226px;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

div::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
div::-webkit-scrollbar-track{
    background: rgb(239, 239, 239);
    border-radius: 2px;
}
div::-webkit-scrollbar-thumb{
    background: #bfbfbf;
    border-radius: 8px;
}

.ant-image {
    display: flex !important;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
}
.ant-image-img {
    width: auto !important;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}