//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.loginModelContainer {
    position: relative;
    a {
        color: $color-primary;
    }
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loginModelView {
        width: 700px;
        height: 498px;
        background-color: $color-white;
        position: relative;
        .loginModelClose {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 10px;
            top: 10px;
            &:hover {
                cursor: pointer;
            }
        }
        .loginModdelTab {
            padding: 24px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $color-border;
            .loginPhone,
            .loginOpen {
                flex: 1;
                text-align: center;
                font-size: 18px;
                color: $color-666;
                &:hover {
                    cursor: pointer;
                }
                &.active {
                    color: $color-primary;
                }
            }
            .loginPhone {
                border-right: 1px solid $color-border;
            }
        }
        .loginTab {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 10px;
            .erropTip {
                min-height: 15px;
                .errorTipView {
                    width: 340px;
                    min-height: 32px;
                    background-color: rgba(254, 59, 48, 0.09);
                    padding: 0 9px;
                    border: 1px solid #fe3b30;
                    display: flex;
                    align-items: center;
                    img {
                        display: block;
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                    span {
                        display: block;
                        color: $color-666;
                        font-size: 14px;
                    }
                }
            }
            .input {
                width: 340px;
                height: 42px;
                line-height: 42px;
                border: 1px solid $color-999;
                display: flex;
                .iconView {
                    width: 42px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #f4f4f4;
                    border-right: 1px solid $color-999;
                }
                input {
                    padding: 0 10px;
                    height: 100%;
                    width: 100%;
                    border: 0;
                }
                &.input1 {
                    margin-top: 10px;
                    .iconView {
                        img {
                            width: 16px;
                            height: 24px;
                        }
                    }
                }
                &.input2 {
                    margin-top: 30px;
                    position: relative;
                    .iconView {
                        img {
                            width: 24px;
                            height: 19spx;
                        }
                    }
                    input {
                        padding-right: 100px;
                    }
                    .codeView {
                        width: 84px;
                        height: 40px;
                        position: absolute;
                        right: 10px;
                        top: 1px;
                        font-size: 12px;
                        color: $color-999;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    .imgCodeView {
                        width: 90px;
                        height: 40px;
                        display: flex;
                        top: 0;
                        right: 0;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            .codeTipView {
                height: 16px;
                margin: 7px 0;
                width: 340px;
                .codeTipBody {
                    display: flex;
                    align-items: center;
                    img {
                        width: 14px;
                        height: 14px;
                        margin-right: 7px;
                        display: block;
                    }
                    span {
                        color: $color-666;
                        font-size: 12px;
                    }
                }
            }
            .button {
                width: 340px;
                height: 42px;
                background-color: $color-primary;
                color: $color-white;
                font-size: 16px;
                border: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .loginTipView {
                margin-top: 30px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                color: $color-666;
                input[type='checkbox'] {
                    width: 12px;
                    height: 12px;
                    border: 1px solid $color-999;
                    border-radius: 0;
                    margin-right: 6px;
                }
            }
        }

        .loginOpenTab {
            padding-top: 10px;
            .erropTip {
                min-height: 15px;
                .errorTipView {
                    width: 540px;
                }
            }
            .formInput {
                border: 0;
                width: 400px;
                margin-top: 20px;
                input {
                    width: 340px;
                    border: 1px solid $color-999;
                }
                .label {
                    flex: 1;
                    text-align: right;
                    color: $color-333;
                }
            }
            .input.input2 {
                margin-top: 20px;
            }
            .select-box {
                display: flex;
                align-items: center;
                .ant-select {
                    .ant-select-selector {
                        height: 42px;
                        border: 1px solid $color-999;
                        input {
                            width: 100%;
                            padding: 0;
                            margin: 0;
                            border: none;
                        }
                    }
                    &.ant-select-focused .ant-select-selector {
                        border-color: $color-primary;
                        border-right-width: 1px !important;
                        outline: 0;
                        box-shadow: none;
                    }
                    &.ant-select-single .ant-select-selector .ant-select-selection-item, 
                    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
                        line-height: 40px;
                    }
                }
                .province-select {
                    width: 90px;
                    margin-right: 10px;
                }
                .city-select {
                    width: 122px;
                    margin-right: 10px;
                }
                .distinct-select {
                    width: 106px;
                }
            }
            .codeTipView {
                height: 16px;
                margin: 2px 0;
                margin-left: 100px;
            }
            .button {
                width: 340px;
                height: 42px;
                background-color: $color-primary;
                color: $color-white;
                font-size: 16px;
                border: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-left: 60px;
                margin-top: 20px;
            }
        }
        .ant-modal-wrap {
            position: absolute;
            top: 23px;
        }
    }
    .userTrack {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 14px;
    }
}

.qr_register_icon {
    width: 100px;
    height: 100px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: $color-primary;
    background-color: $color-white;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: $color-primary;
    background-color: #eeeeee;
}