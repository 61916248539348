//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.container {
  margin-left: 8px;
}
.brand {
  width: 360px;
  max-height: 480px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  .brand-content {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 12px;
    margin-bottom: 48px;
  }
  .item {
    margin: 12px 12px;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
    padding: 4px 20px;
    border-radius: 10px;
    font-size: 12px;
  }
  &__normal {
    background-color: $color-bg-f5;
    color: $color-333;
  }
  &__active {
    color: $color-primary;
    background: $color-btn-verify-active;
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 48px;
    background-color: #fff;
    .bottom-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &__btn {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 32px;
        background: hsl(0, 0%, 100%);
        border-radius: 32px;
        font-size: 14px;
      }
    }
    .confirm {
      background-color: $color-primary;
      color: #fff;
      margin-right: 20px;
    }
    .reset {
      border: 1px solid #cccccc;
      color: #222;
      margin-right: 30px;
    }
  }
}