//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.BottomContainer {
    position: absolute;
    bottom: 0;
    width: 100%;

    .BottomTopView {
        min-width: 1226px;
        display: flex;
        justify-content: center;
        background-color: $color-white;

        .TopView {
            width: 1226px;

            .advantageView {
                display: flex;
                flex-direction: row;
                padding: 28px 0;
                align-items: center;
                border-top: 1px solid #e0e0e0;
                border-bottom: 1px solid $color-border;

                .advantageItem {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    border-right: 1px solid $color-border;
                    align-items: center;

                    &:last-child {
                        border-right: 0;
                    }

                    img {
                        width: 22px;
                        height: 21px;
                    }

                    &:nth-of-type(2) {
                        img {
                            width: 18px;
                            height: 23px;
                        }
                    }

                    &:nth-of-type(3) {
                        img {
                            width: 22px;
                            height: 22px;
                        }
                    }

                    &:nth-of-type(4) {
                        img {
                            width: 23px;
                            height: 20px;
                        }
                    }

                    &:nth-of-type(5) {
                        img {
                            width: 24px;
                            height: 20px;
                        }
                    }

                    span {
                        margin-left: 10px;
                        color: $color-666;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }

            .advantageDescView {
                padding: 30px 0 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .descBlock {
                    font-size: 14px;
                    color: $color-666;
                    line-height: 21px;

                    .descTitle {
                        font-size: 22px;
                        color: $color-primary;
                        font-weight: 500;
                        margin-bottom: 9px;
                    }
                }

                .webappBlock {
                    // padding-top: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &>div {
                        font-size: 14px;
                        color: $color-333;
                        font-weight: 500;
                    }

                    img {
                        width: 120px;
                        height: 120px;
                        margin-top: 6px;
                    }
                }
            }
        }
    }

    .BottomBottomView {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background-color: #f7f7f7;
        min-width: 1226px;

        .BottomView {
            width: 1226px;
            display: flex;
            justify-content: space-between;

            .bottomABlock {
                display: flex;
                flex-direction: row;
                font-size: 14px;
                color: $color-333;

                .bottomAItem {
                    margin-right: 30px;
                }

                .hover:hover {
                    cursor: pointer;
                    color: $color-primary;
                }
            }

            .companyInfo {
                font-size: 14px;
                color: $color-333;
            }
        }
    }
}