//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.orderDetailListSubItem {
    display: flex;
    flex-direction: row;
    height: 100px;
    width: 100%;
    padding-top: 20px;

    .itemHead {
        width: 373px;
        margin-left: 64px;
        display: flex;
        flex-direction: row;

        .headImage {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFFFFF;
            Image {
                max-width: 60px;
                max-height: 60px;
            }
        }

        .itemName {
            width: 200px;
            height: 36px;
            font-size: 12px;
            font-family: Helvetica;
            color: rgba(51, 51, 51, 1);
            line-height: 18px;
            margin-left: 20px;
        }
    }

    .itemType {
        width: 177px;
    }

    .itemPrice {
        width: 177px;
    }

    .itemCount {
        width: 170px;
    }

    .itemDiscount {
        width: 177px;
    }

    .itemAmount {
        width: 177px;
    }

    .itemRemark {
        width: 132px;
        .showItem {
            display: flex;
            flex-direction: row;
            height: 36px;
            align-items: flex-end;
            .moreButton {
                .moreText {
                    display: inline-block;
                    position: relative;
                    width: 6px;
                    height: 6px;
                    margin-right: 5px;
                }
                .moreText::after {
                    display: inline-block;
                    content: '';
                    height: 6px;
                    width: 6px;
                    border-width: 0 1px 1px 0;
                    border-color: #999999;
                    border-style: solid;
                    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
                    transform-origin: center;
                    transition: transform 0.3s;
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                }
            }
        }
    }

    .remarkText {
        color: $color-333;
        width: 86px;
        height: 36px;
    }

    .itemText {
        font-size: 12px;
        font-family: Helvetica;
        color: rgba(51, 51, 51, 1);
        line-height: 18px;
    }

    .ellipsis {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 2;
    }

    .contentCopy {
        display: inline-block;
        width: 86px;
        visibility: hidden;
        // background-color: red;
    }

    .orderDetailDeleteTag {
        position: absolute;
        width: 32px;
        height: 16px;
        background-color: #cccccc;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 16px;
        margin-top: 20px;
        margin-left: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .orderDetailAddTag {
        position: absolute;
        width: 32px;
        height: 16px;
        background-color:#003984;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 16px;
        margin-top: 20px;
        margin-left: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .orderDetailReplaceTag {
        position: absolute;
        width: 32px;
        height: 16px;
        background-color:#FE3B30;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 16px;
        margin-top: 20px;
        margin-left: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.toolTipRemark {
    width: 108px;
    height: fit-content;
    font-size: 12px;
    font-family: Helvetica;
    color: rgba(51, 51, 51, 1);
    line-height: 14px;
}
