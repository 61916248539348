//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.statusOrderContent {
    height: 90px;
    display: flex;
    flex-direction: row;
    width: 761px;

    .headImage {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        Image {
            max-height: 60px;
            max-width: 60px;
        }
    }

    .orderTitle {
        margin-top: 15px;
        width: 284px;
        padding-left: 10px;
        font-size: 12px;
        font-family: Helvetica;
        color: rgba(51, 51, 51, 1);
        line-height: 18px;
    }

    .orderPriceView {
        width: 104px;
        height: 100%;
        padding-left: 10px;
        padding-top: 15px;

    }

    .orderItemView {
        padding-top: 15px;
        border-left-width: 1px;
        border-left-color: $color-border;
        border-left-style: solid;
    }

    .orderCountView {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 139px;
    }

    .orderAmountView {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 164px;
    }

    .orderStatusView {
        width: 160px;
        padding-left: 55px;
    }

    .orderOperation {
        width: 104px;
        padding-left: 24px;
    }

    .orderText {
        font-size: 12px;
        font-family: Helvetica;
        color: rgba(51, 51, 51, 1);
        line-height: 18px;
    }
}