//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.oneOrderListContent {
    width: 1026px;
    height: auto;
    margin-left: 20px;
    margin-top: 20px;
    border: 1px solid rgba(224, 224, 224, 1);

    .innerOrderListContent {
        .oneOrderListHeader {
            height: 34px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: rgba(243, 243, 243, 1);
            border-bottom: 1px solid rgba(224, 224, 224, 1);

            .orderNumView {
                width: 260px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 10px;
                color: $color-666;
                font-size: 12px;
                line-height: 14px;
            }

            .orderNum {
                color: $color-333;
            }

            .orderNumNone {
                color: #FE3B30;
            }

            .orderTime {
                font-size: 12px;
                font-family: Helvetica;
                color: rgba(102, 102, 102, 1);
                line-height: 14px;
                padding-left: 10px;
            }

            .orderSource {
                margin-left: 34px;
                font-size: 12px;
                font-family: Helvetica;
                color: rgba(102, 102, 102, 1);
                line-height: 14px;
            }
        }
    }

    .orderList {
        display: flex;
        flex-direction: row;

        .orderStatusView {
            width: 160px;
            padding-left: 55px;

            .statusRed {
                color: #FE3B30;
                font-size: 12px;
                line-height: 18px;
            }

            .statusGray {
                color: $color-999;
                font-size: 12px;
                line-height: 18px;
            }

            .statusBlue {
                color: #003984;
                font-size: 12px;
                line-height: 18px;
            }

            .orderDetailText {
                margin-top: 6px;
                color: $color-333;
                font-size: 12px;
                line-height: 18px;
            }
        }

        .orderOperation {
            width: 104px;
            padding-left: 24px;
            display: flex;
            flex-direction: column;

            .orderAfterSale {
                font-size: 12px;
                font-family: Helvetica;
                color: $color-333;
                line-height: 18px;
                margin-top: 6px;
            }
        }

        .orderItemView {
            padding-top: 15px;
            border-left-width: 1px;
            border-left-color: $color-border;
            border-left-style: solid;
        }

        .orderText {
            font-size: 12px;
            font-family: Helvetica;
            color: rgba(51, 51, 51, 1);
            line-height: 18px;

            &:hover {
                cursor: pointer;
                color: $color-primary;
            }
        }

        .statusView {
            font-size: 12px;
            font-family: Helvetica;
            color: rgba(153, 153, 153, 1);
            line-height: 18px;
        }

        .redStatusView {
            color: rgba(254, 59, 48, 1);
        }

        .processStatusView {
            color: rgba(0, 57, 132, 1);
        }
    }

}

.moreItemView {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;
    font-family: Helvetica;
    color: #666666;
    line-height: 17px;
    .moreTextHighColor {
        font-size: 14px;
        font-family: Helvetica;
        color: #003984;
        line-height: 17px;
        font-weight: 500;
        margin-left: 1px;
        margin-right: 1px;
    }
    .moreButtonTextHighColor {
        font-size: 14px;
        font-family: Helvetica;
        color: #003984;
        line-height: 17px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .moreView {
        display: flex;
        flex-direction: row;
        .moreText {
            img {
                width: 8px;
                margin-left: 5px;
            }
        }
    }
}