//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.detailOrderInfoContent {
    width: 1226px;
    margin-top: 20px;
    background-color: $color-white;
    padding-bottom: 20px;

    .infoHeader {
        width: 100%;
        height: 40px;
        background: rgba(243, 243, 243, 1);
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 14px;
        font-family: Helvetica;
        color: rgba(51, 51, 51, 1);
        line-height: 17px;
    }

    .infoContent {
        display: flex;
        flex-direction: row;

        .orderInfoView {
            width: 500px;
            padding-left: 20px;
            padding-top: 14px;
        }

        .orderNextInfoView {
            width: auto;
        }

        .orderInfoItem {
            font-size: 12px;
            font-family: Helvetica;
            color: rgba(51, 51, 51, 1);
            line-height: 14px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-top: 6px;
            .orderHeadText {
                width: 86px;
                height: 16px;
                font-size: 12px;
                font-family: Helvetica;
                color: rgba(153, 153, 153, 1);
                line-height: 14px;
            }
            .orderInfoText {
                height: 16px;
            }

            .addressText {
                display: flex;
                flex: 1;
            }

            .remarkText {
                display: flex;
                flex: 1;
                word-break: break-all;
                height: auto;
            }
        }
    }
}
