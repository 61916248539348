//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.headerContent {
    width: 1026px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(243, 243, 243, 1);
    margin-top: 20px;
    margin-left: 20px;
    border-top: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    .headerTitleView {
        width: 354px;
        padding-left: 10px;
    }

    .headerPrice {
        width: 104px;
        padding-left: 10px;
    }

    .headerCount {
        width: 139px;
        display: flex;
        justify-content: center;
    }

    .headerAmount {
        width: 164px;
        display: flex;
        justify-content: center;
    }

    .headerStatus {
        width: 160px;
        display: flex;
        justify-content: center;
    }

    .headerOperation {
        width: 104px;
        display: flex;
        justify-content: center;
    }


    .title {
        color: $color-666;
        font-size: 12px;
        line-height: 14px;
    }

}

.menuItem {
    color: $color-666;
    font-size: 12px;
    line-height: 14px;
    height: 40px;
    display: flex;
    align-items: center;

}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    color: #666666;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    a {
        color: #666666;
        font-size: 12px;
        line-height: 14px;
    }
}

.ant-dropdown-menu-item > a:hover, .ant-dropdown-menu-submenu-title > a:hover {
    color: #003984;
    font-size: 12px;
    line-height: 14px;
}

.orderListHeaderDropView {
    display: flex;
    flex-direction: row;
    align-items:center;
    width: 109px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(224, 224, 224, 1);
    .title {
        font-size: 12px;
        font-family: Helvetica;
        color: #666666;
        line-height: 14px;
        margin-left: 10px;
        display: flex;
        flex: 1;
        &:hover {
            color: $color-primary;
        }
    }
    .orderListHeaderdropIcon {
        height: 12px;
        margin-right: 10px;
    }
}

.ant-picker-range {
    height: 28px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $color-primary;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $color-primary;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
}





// .ant-select-item-option {
//     color: #666666;
//     font-size: 12px;
//     line-height: 14px;
//     display: flex;
//     align-items: center;
// }