//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.detailStatusContent {
    width: 1226px;
    background-color: $color-white;

    .header {
        height: 40px;
        background: rgba(243, 243, 243, 1);
        // border: 1px solid rgba(224, 224, 224, 1);
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 14px;
        font-family: Helvetica;
        color: rgba(51, 51, 51, 1);
        line-height: 17px;

        .headerStatus {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(254, 59, 48, 1);
            line-height: 20px;
        }
    }

    .content {
        padding-left: 20px;
        padding-top: 20px;
        // padding-bottom: 20px;

        .delayTimeView {
            font-size: 12px;
            font-family: Helvetica;
            color: rgba(51, 51, 51, 1);
            line-height: 14px;
            margin-bottom: 20px;
            .delayTimeText {
                color: rgba(254, 59, 48, 1);
            }

            .delayTimeIcon {
                width: 11px;
                height: 11px;
                margin-right: 3px;
            }
        }

        .countView {
            font-size: 12px;
            font-family: Helvetica;
            color: rgba(51, 51, 51, 1);
            line-height: 14px;

            .timeCount {
                color: rgba(254, 59, 48, 1);
            }
        }

        .statusInfo {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 12px;
            font-family: Helvetica;
            color: #333333;
            line-height: 14px;
            margin-bottom: 20px;
            .info-title-icon {
                width: 3px;
                height: 3px;
                background-color: #cccccc;
                margin-right: 7px;
                margin-left:4px;
            }
            .cancelOrderButton {
                width: 68px;
                height: 25px;
                background-color: #cccccc;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .statusInfoText {
                font-size: 12px;
                font-family: Helvetica;
                color: rgba(51, 51, 51, 1);
                line-height: 14px;
            }
            .cancelButton {
                font-size: 12px;
                line-height: 14px;
            }
            .sendButton {
                font-size: 12px;
                line-height: 14px;
                margin-right: 6px;
            }
            .moreOrderButton {
                width: 68px;
                height: 25px;
                background-color: $color-primary;
                margin-left: 4px;
                font-size: 12px;
                font-family: Helvetica;
                color: rgba(255, 255, 255, 1);
                line-height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .infoText {
                margin-right: 5px;
            }
        }
    }
}

.cartModel {
    .ant-modal-close-x {
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-modal-header {
        padding: 6px 10px;
        background: #f3f3f3;

        .ant-modal-title {
            font-size: 14px;
            color: $color-333;
        }
    }

    .ant-modal-body {
        height: 150px;
        padding: 0;

        .delectTipView {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-top: 10px;
            margin-left: 28px;
            color: $color-333;
            img {
                width: 48px;
                margin-right: 10px;
            }
        }

        .addTipView {
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-top: 10px;
            margin-left: 28px;
            color: $color-333;
            img {
                width: 48px;
                margin-right: 10px;
            }

            Input {
                width: 300px;
                margin-left: 10px;
            }
        }

        .cartModelButton {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            button {
                border: 0;
                font-size: 12px;
                // width: 44px;
                // height: 25px;
                line-height: 25px;

                &:hover {
                    cursor: pointer;
                }

                &.cancelBtn {
                    background-color: #f6f6f6;
                    border: 1px solid #cccccc;
                    color: $color-333;
                    margin-right: 10px;
                }

                &.submitBtn {
                    background-color: $color-primary;
                    color: $color-white;
                    width: auto;
                    padding-left: 5px;
                    padding-right: 5px;
                }

                &.sendSubmitBtn {
                    background-color: $color-primary;
                    color: $color-white;
                    padding-left: 5px;
                    padding-right: 5px;
                    margin-right: 10px;
                    width: 88px;
                }

                &.sendCancelBtn {
                    background-color: #f6f6f6;
                    border: 1px solid #cccccc;
                    width: 88px;
                    color: $color-333;
                    margin-right: 10px;
                }

                &.uploadBtn {
                    width: 88px;
                    margin-left: -140px;
                }
            }
        }

        .remarkView {
            display: flex;
            margin-top: 10px;
            margin-left: 29px;

            textarea {
                width: 299px;
                height: 80px !important;
                resize: none;
                border-color: #cccccc;
            }
        }

        .editView {
            margin-top: 10px;
            margin-left: 29px;

            .editViewBlock {
                display: flex;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }

                .editSelectBlock {
                    border: 1px solid #cccccc;
                    padding: 4px 14px;
                    color: $color-333;
                    font-size: 12px;
                    line-height: 17px;
                    margin-right: 10px;
                    position: relative;

                    &.active {
                        border-color: $color-primary;

                        &::after {
                            content: '';
                            width: 12px;
                            height: 12px;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            background: linear-gradient(
                                to bottom right,
                                $color-white 50%,
                                $color-primary 50%,
                                $color-primary
                            );
                            z-index: 1;
                        }

                        &::before {
                            content: '';
                            width: 6px;
                            height: 4px;
                            position: absolute;
                            border-bottom: 1px solid $color-white;
                            border-left: 1px solid $color-white;
                            right: 0;
                            bottom: 2px;
                            z-index: 2;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }

        .exportView {
            margin-top: 10px;
            margin-left: 29px;
            font-size: 12px;

            & > div:first-child {
                color: $color-333;
            }

            .exportInputView {
                display: flex;
                align-items: center;
                color: $color-666;
                margin-top: 9px;

                input {
                    width: 200px;
                    height: 28px;
                    border: 1px solid #cccccc;
                    padding: 0;
                    background-color: transparent;
                }

                a {
                    color: $color-primary;
                    margin-left: 6px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .selectFileView {
                    position: relative;
                    width: 54px;
                    height: 18px;
                    white-space: nowrap;

                    input {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        opacity: 0;
                    }
                }
            }
        }

        .changeTipView {
            margin-top: 10px;
            margin-left: 29px;
            font-size: 12px;
            color: $color-333;

            span {
                color: #fe3b30;
            }
        }
    }
}

.ant-btn:hover, .ant-btn:focus {
    color: $color-primary;
    border-color: $color-primary
}