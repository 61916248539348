//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.categoryContainer {
    height: 37px;
    background-color: $color-white;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid $color-primary;
    .positionView {
        position: relative;
        width: 1226px;
    }
    .categoryView {
        height: 100%;
        .categoryBlock {
            background-color: $color-primary;
            width: 234px;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 40px;
            img {
                width: 16px;
                height: 16px;
            }
            span {
                font-size: 16px;
                color: $color-white;
                display: block;
                margin-left: 10px;
            }
        }
    }
    .categoryPanel {
        position: absolute;
        z-index: 1000;
        background-color: transparent;
        height: 386px;
        display: none;
        top: 35px;
        left: -6px;
        padding-top: 2px;
        .catalogContainer {
            width: 240px;
            position: relative;
            &::before {
                content: '';
                display: block;
                height: 100%;
                width: 234px;
                top: 0;
                right: 0;
                position: absolute;
                z-index: 1;
                background-color: rgba(0, 0, 0, 0.6);
            }
            .catalogContainerView {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                background-color: transparent;
                overflow-y: auto;
            }
            .lastBlock {
                height: 6px;
                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    height: 6px;
                    width: 234px;
                    margin-left: 6px;
                    z-index: 1;
                    clear: both;
                }
            }
            .lastclassificationBlock {
                height: 6px;
            }
            .catalogBlock {
                height: 32px;
                position: relative;
                cursor: pointer;
                span {
                    color: $color-white;
                    font-size: 14px;
                    position: absolute;
                    display: flex;
                    padding-top: 7px;
                    z-index: 3;
                    height: 100%;
                    width: 100%;
                    padding-left: 46px;
                }

                &:hover {
                    &::after {
                        content: ' ';
                        position: absolute;
                        display: block;
                        height: 100%;
                        width: 100%;
                        background-color: $color-primary;
                        z-index: 2;
                    }
                    i {
                        width: 0;
                        height: 0;
                        border-top: 6px solid #051e40;
                        border-left: 6px solid transparent;
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -6px;
                    }
                }
            }
        }
        .classificationContainer {
            width: 992px;
            background-color: $color-white;
            box-shadow: 3px 5px 8px 3px rgba(228, 228, 228, 0.5);
            padding-top: 20px;
            overflow-y: auto;
            .classificationBlock {
                display: flex;
                align-items: baseline;
                margin-bottom: 20px;
                .classificationBlockParent {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    justify-content: flex-end;
                    padding-right: 30px;
                    color: $color-333;
                    font-weight: 500;
                    font-size: 14px;
                    cursor: pointer;
                    &::after {
                        content: '';
                        background-image: url(https://img.dmallcdn.com/dshop/202008/f63c16f0-ac1d-448e-a31c-1d1cdf01823c);
                        display: block;
                        width: 8px;
                        height: 13px;
                        background-size: 100% 100%;
                        margin-left: 5px;
                        margin-bottom: 2px;
                    }
                }
                .classificationBlockChildren {
                    width: 840px;
                    color: $color-666;
                    display: flex;
                    align-items: baseline;
                    font-size: 14px;
                    flex-wrap: wrap;
                    .classificationBlockChild {
                        margin-right: 16px;
                        &:last-child {
                            margin-right: 0;
                        }
                        &:hover {
                            cursor: pointer;
                            color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}
