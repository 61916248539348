//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.saleListItemContent {
    display: flex;
    height: 90px;
    .saleNameDetail {
        width: 428px;
        display: flex;
        flex-direction: row;
        padding-top: 10px;

        .headImage {
            width: 60px;
            height: 60px;
            margin-left: 10px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFFFFF;
            Image {
                max-width: 60px;
                max-height: 60px;
            }
        }
    }

    .itemName {
        font-size: 12px;
        color: #333333;
        line-height: 18px;
    }

    .headItem {
        padding-top: 10px;
        border-right: 1px solid #E0E0E0;
        display: flex;
        flex: 1;
        justify-content: center;
        font-size: 12px;
        color: #333333;
        line-height: 18px;
    }

    .headItemRed {
        color:#fe3b30;
        font-size: 12px;
        line-height: 18px;
    }

    .headItemGray {
        color: $color-666;
        font-size: 12px;
        line-height: 18px;
    }

    .status {
        flex: 1;
    }

    .operation {
        display: flex;
        flex: 1;

        .operationView {
            display: flex;
            flex-direction: column;
            .operationButton {
                color: $color-333;
                font-size: 12px;
                line-height: 18px;
                &:hover {
                    cursor: pointer;
                    color: $color-primary;
                }
            }
        }
    }

    .approveModal {
        padding: 10px 28px 28px 10px;
    }
}

.approveInfoList {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .approveInfoText {
        width: 100px;
    }

    .inputView {
        width: 220px;
        height: 120px;
    }

    .submitBtn {
        width: 88px;
        height: 30px;
        background: #003984;
        border: 1px solid #003984;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
