//可以在这里统一管理一些通用的css属性，便于换肤

$color-primary: #003984; // 主题色

$color-primary-btn-hover: #005eda; //按钮hover颜色

$color-black: #000000; //黑色

$color-white: #ffffff; //白色

$color-border: #e0e0e0; //边框颜色

$color-333: #333333;

$color-666: #666666;

$color-999: #999999;

$color-bg-img: #FFFCD1;

$color-bg-f5: #f5f5f5;

$color-333: #333333;

$color-btn-verify-active: rgba(18, 109, 226, 0.1);

/*适用于灰色按钮*/
@mixin btn-hover {
    // &:hover {
    //     color: $color-primary;
    //     border: 1px $color-primary solid;
    //     transition: ease-in-out 0.3s;
    // }
}

/*适用于深色按钮*/
@mixin btn-primary-hover {
    // &:hover {
    //     background-color: $color-primary-btn-hover;
    //     transition: ease-in-out .3s;
    //     border: 1px solid $color-primary-btn-hover;
    // }
}

.ant-pagination-prev:focus,
.ant-pagination-prev:hover,
.ant-pagination-next:focus,
.ant-pagination-next:hover,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
    border-color: $color-primary;
    .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
    }
    a {
        color: $color-primary;
    }
}
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
}
//可以在这里统一管理一些通用的class样式

/*图片自适应高宽，可能需要配合父容器使其居中*/
@mixin img-auto-size {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.agreement-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .agreement-body {
        position: relative;
        display: flex;
        flex-direction: column;
        background: $color-white;
        width: 654px;
        height: 486px;
        .header-box {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F3F3F3;
            font-size: 14px;
            color: $color-333;
        }
        .content-box {
            flex: 1;
            padding: 10px 24px 10px 30px;
            margin-right: 6px;
            overflow: auto;
            font-size: 14px;
            color: $color-666;
            &::-webkit-scrollbar{
                width: 6px;
                height: 6px;
            }
            &::-webkit-scrollbar-track{
                background: $color-white;
                border-radius: 2px;
            }
            &::-webkit-scrollbar-thumb{
                background: $color-border;
                border-radius: 3px;
            }
        }
        .btn-box {
            width: 100%;
            height: 66px;
            display: flex;
            align-items: center;
            justify-content: center;
            .confirm-btn {
                width: 152px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: $color-white;
                background: $color-primary;
                cursor: pointer;
                -moz-user-select:none;/*火狐*/
                -webkit-user-select:none;/*webkit浏览器*/
                -ms-user-select:none;/*IE10*/
                -khtml-user-select:none;/*早期浏览器*/
                user-select:none;
            }
        }
    }
}